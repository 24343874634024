// extracted by mini-css-extract-plugin
export var buttons = "index-module--buttons--Sp-t-";
export var content = "index-module--content--DFMhm";
export var contentWrapper = "index-module--contentWrapper--OdWvK";
export var desc = "index-module--desc--9jKvi";
export var icon = "index-module--icon--wxxWH";
export var iconText = "index-module--iconText--P3HLg";
export var left = "index-module--left--G+9iv";
export var overViewCardWrapper = "index-module--overViewCardWrapper--+WPWV";
export var positionBg = "index-module--positionBg--BNqo+";
export var right = "index-module--right--n99z+";
export var tag = "index-module--tag--obvOI";
export var tagMb = "index-module--tagMb--wn3Hn";
export var tip = "index-module--tip--cl2+Q";
export var title = "index-module--title--aAELB";
export var titleWrapper = "index-module--titleWrapper--KDRnx";