import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/zh'
import {
  Seo,
  Banner,
  SummaryTitle,
  ImagePreview,
  ScanCodeConsultButton,
  TabSwitcher,
  Button,
  PageBottomCard,
} from 'components/index'
import { ButtonGroup } from 'components/Base/Button'
import OverViewCard from 'components/Features/OverViewCard'
import ProductCard from 'components/Features/ProductCard'
import Evaluation from 'components/Features/Evaluation'
import Information from 'components/Product/Information'
import SubscribeForm from 'components/SubscribeForm'
import Blog from 'components/Product/Blog'
import Community from 'components/Product/Community'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import * as styles from './index.module.less'

const overView = (props) => {
  const { data } = props
  const { featuresOverviewYaml, productsYaml } = data
  const { banner, part2, part3, part4, part5, part6 } = featuresOverviewYaml
  const { sa_T, six_part, seven_part, eight_part } = productsYaml

  const [showForm, setShowForm] = useState<boolean>(false)

  const isMb = useIsWindowWidthSmaller()

  const buttons = [
    {
      useQrCode: true,
      buttonText: '立即咨询',
      qrcode: 'https://ow-file.sensorsdata.cn/www/product/analysis/wechatQRcode.png',
      imgBottomText: '微信扫码 立即咨询',
      btnType: 'primary',
    },
    {
      text: '预约演示',
      href: '/form/parade.html',
      ghost: true,
      btnType: 'primary',
    },
  ]

  return (
    <Layout {...props}>
      <Seo
        title="神策三大引擎 | 产品体系介绍 - 神策数据"
        description="神策数据三大引擎包括客户数据引擎、客户旅程优化引擎和客户旅程分析引擎，致力于帮助企业搭建客户数据平台，实现基于客户旅程的用户体验管理，为企业数字化客户经营提供解决方案。"
        keywords="神策产品矩阵, 三大引擎,数字化客户经营,CJO客户旅程编排,产品体系,产品介绍"
      />
      <div className={styles.featuresOverviewWrapper}>
        <div className={styles.bannerWrapper}>
          <Banner
            {...banner}
            buttons={buttons}
            h1tag={true}
            className={classnames(styles.banner)}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            bannerBtnsClass={styles.bannerBtnsClass}
          />
        </div>
        <div className={styles.wWarrper}>
          <div className="whitespace-pre">
            <SummaryTitle
              title={isMb ? '从理念、方法到引擎\n全面赋能数字化客户经营' : '从理念、方法到引擎，全面赋能数字化客户经营'}
            />
          </div>
          <div className="flex justify-center md:mt-[60px] mt-[3rem] px-[2rem]">
            <div className="lg:w-[933px]">
              <ImagePreview imageUrl={part2.img.publicURL ?? part2.img} />
            </div>
          </div>
          <div className="flex justify-center">
            <ScanCodeConsultButton
              className="lg:mt-[60px] mt-[3rem] bg-[#fff] hover:!bg-[#E6FAF5]"
              qrcode="https://ow-file.sensorsdata.cn/www/solutions/overview/wxcode.png"
              size="small"
              target="_blank"
              buttonText="立即咨询"
              imgBottomText="微信扫码 立即咨询"
              // ghost
              buttonType="primary"
            />
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="基于客户旅程编排的三大引擎" />
          <div className={styles.partContent}>
            <TabSwitcher
              data={part3.data}
              align="flex-start"
              mbShowShadow={false}
              autoWAndH
              longTitle
              itemContenWrapperClass={styles.itemContenWrapperClass}
              tabsClass={isMb ? null : styles.tabsClass}
              tabItemClass={isMb ? null : styles.tabItemClass}
              detailCenter={false}
            />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <OverViewCard {...part4} />
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="更多应用产品" />
          <div className="md:mt-[60px] mt-[1.6rem] overflow-hidden">
            <ProductCard />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="客户旅程编排的产品全景图" />
          <div className="flex justify-center md:mt-[40px] md:px-0 mt-[3rem] px-[2rem]">
            <div className="lg:w-[1295px]">
              <ImagePreview imageUrl={part5.img.publicURL ?? part5.img} alt="客户旅程编排的产品全景图" />
            </div>
          </div>
          <ButtonGroup className={classnames('md:mt-[60px] mt-[3rem] flex justify-center', styles.buttons)}>
            <Button href="https://www.sensorsdata.cn/form/parade.html" target="_blank" btnType="primary">
              预约演示
            </Button>
            <Button href="https://www.sensorsdata.cn/demo/demo.html" target="_blank" btnType="primary" ghost>
              体验 Demo
            </Button>
          </ButtonGroup>
          <div className={styles.part6}>
            {isMb ? null : <SummaryTitle title="客户评价" />}
            <div className="lg:mt-[60px]">
              <Evaluation data={part6?.data} />
            </div>
          </div>
          <div className="lg:pt-[100px] pt-[4.8rem]">
            <Information data={six_part} saTitle={sa_T} handleForm={() => setShowForm(true)} />
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="最新产品技术文章" />
          <div className="mt-[2.6rem]">
            <Blog firstData={seven_part?.firstData} data={seven_part?.list} />
          </div>
        </div>
        <div className="md:bg-[#fff] bg-[#F9FAFC] overflow-hidden">
          <Community data={eight_part} />
        </div>
        <PageBottomCard
          title="开启大数据分析与营销科技之旅！"
          desc="立即注册，和神策数据专家一起探讨数字化！"
          leftButtonText="体验 Demo"
          leftButtonHref="/demo/demo.html"
          rightButtonText="预约演示"
          rightButtonHref="/form/parade.html"
          type="newBg"
        />
        {showForm && <SubscribeForm handleClose={() => setShowForm(false)} spare_channel="subscription_update" />}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    featuresOverviewYaml {
      banner {
        title
        desc
      }
      part2 {
        img {
          publicURL
        }
      }
      part3 {
        data {
          title
          reverse
          showIcon
          sortTitle
          content {
            img {
              publicURL
            }
            alt
            introduction1
            enintroduction1
            codeDesc
            buttons {
              btnType
              href
              text
              target
              ghost
            }
            introductionList {
              content
            }
          }
        }
      }
      part4 {
        positionBg {
          publicURL
        }
        content {
          title
          tag
          desc
          icon {
            publicURL
          }
          img {
            publicURL
          }
          iconText
          buttons {
            text
            btnType
            href
            target
          }
        }
      }
      part5 {
        img {
          publicURL
        }
      }
      part6 {
        data {
          tab
          widget_id
          desc
          qrcode
          btnText
        }
      }
    }
    productsYaml {
      sa_T
      six_part {
        arrow_icon
        bg
        mb_bg
        buttonText
        desc
        link
        name
        position
        title
      }
      seven_part {
        firstData {
          buttonText
          desc
          link
          name
          poster
          poster_mb
          arrow_icon
        }
        list {
          buttonText
          desc
          link
          name
          tagName
          arrow_icon
          tagWrap
        }
      }
      eight_part {
        buttonText
        desc
        en_title
        link
        title
        bg
        hover_bg
        arrow_icon
      }
    }
  }
`

export default overView
