import React, { useState, useEffect, useContext } from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { getUserCertificationNew } from 'api/index'
import useLoginDialog from 'hooks/useLoginDialog'
import { providerContext } from 'components/Provider'
import { isPc } from 'utils/utils'
import { saTrackSupportLoginClick } from 'utils/statistics.js'
import classnames from 'classnames'
interface dataProp {
  arrow_icon?: any
  bg?: any
  mb_bg?: any
  buttonText?: string
  desc?: string
  link?: string
  name?: string
  position?: string
  title?: string
}
interface IntroduceProps {
  data?: dataProp[]
  saTitle?: string
  handleForm?: any
}
SwiperCore.use([Pagination])
export const Information: React.FC<IntroduceProps> = ({ data, saTitle, handleForm }) => {
  const [pcShow, setPcShow] = useState(true)
  let { user } = useContext(providerContext)

  const handleClick = (link) => {
    if (link) {
      window.open(link)
      return
    }
    //处理订阅通知流程
    handleForm?.()
  }

  useEffect(() => {
    setPcShow(isPc())
  }, [])

  return (
    <ul className={classnames('flex justify-between w-[35.6rem] lg:w-[1200px] mx-auto my-0')}>
      {data?.map((item) => (
        <li
          key={item.title}
          className="w-[17.8rem] h-[14.7rem] rounded-[4px] lg:w-[550px] lg:h-[394px] py-[2.8rem] lg:py-[54px] px-[2.3rem] lg:px-[44px] m-[5px] bg-[length:100%_100%] lg:hover:bg-[length:108%_108%] bg-no-repeat lg:shadow-[0_2px_10px_0_rgb(193,208,211,42%)] cursor-pointer"
          style={{
            backgroundImage: `url(${pcShow ? item?.bg?.publicURL ?? item?.bg : item?.mb_bg?.publicURL ?? item?.mb_bg})`,
            transition: `background-size .3s ease-out`,
          }}
          onClick={() => handleClick(item?.link)}
        >
          <span className="text-[#1F2D3D] text-[16px] lg:text-[18px] font-medium">{item.title}</span>
          <div className="hidden lg:block lg:h-[160px] text-[#475669] text-[14px] pt-[29px]">
            <span>{item.desc}</span>
            <p className="text-[#475669] text-[12px] pt-[5px] text-right">
              <span className="mr-[5px]">{item?.position}</span>
              {item?.name}
            </p>
          </div>

          <p className="flex items-center text-[#04CB94] text-[12px] lg:text-[14px] mt-[2.2rem] lg:mt-[54px]">
            <span className="pr-[4px] lg:pr-[7px]">{item.buttonText}</span>
            <img src={item?.arrow_icon?.publicURL ?? item?.arrow_icon} />
            <img src={item?.arrow_icon?.publicURL ?? item?.arrow_icon} />
          </p>
        </li>
      ))}
    </ul>
  )
}

export default Information
