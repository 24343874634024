import React, { CSSProperties } from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'

import { Button } from 'components/index'

interface OverViewCardProps {
  /**自定义样式 */
  customClass?: CSSProperties
  /**背景图片 */
  positionBg: { publicURL: string } | string
  /**右侧内容 */
  content: {
    /**标题 */
    title: string
    /**标签 */
    tag?: string
    /**描述 */
    desc: string
    /**icon */
    icon?: { publicURL: string } | string
    /**icon后面的文字 */
    iconText?: string
    /**图片 */
    img?: { publicURL: string } | string
    /**按钮 */
    buttons: {
      /**按钮文字 */
      text: string
      /**按钮类型 */
      btnType?: string
      /**跳转链接 */
      href?: string
      /**跳转方式 */
      target?: string
    }[]
  }
}
interface ExtendedCSSProperties extends CSSProperties {
  /**背景图 */
  '--positionBg'?: string
  '--icon'?: string
  '--book'?: string
}

const OverViewCard: React.FC<OverViewCardProps> = (props) => {
  const { customClass, positionBg, content } = props
  const { title, tag = '', desc = '', icon, iconText = '', buttons, img } = content
  const isMb = useIsWindowWidthSmaller()
  const customStyle: ExtendedCSSProperties = {
    '--positionBg': typeof positionBg === 'string' ? `url(${positionBg})` : `url(${positionBg.publicURL})`,
    '--icon': typeof icon === 'string' ? `url(${icon})` : `url(${icon?.publicURL})`,
    '--book': typeof img === 'string' ? `url(${img})` : `url(${img?.publicURL})`,
  }
  return (
    <div className={classnames(styles.overViewCardWrapper, customClass)} style={customStyle}>
      <div className={styles.positionBg}>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.left}>
              <div className={styles.titleWrapper}>
                <div className={styles.title}>
                  {title}
                  {isMb && <div className={styles.tagMb}>{tag}</div>}
                </div>
                <div className={styles.tag}>{tag}</div>
              </div>
              <div className={styles.desc}>{desc}</div>
              <div className={styles.tip}>
                <div className={styles.icon}></div>
                <div className={styles.iconText}>{iconText}</div>
              </div>
              <div className={styles.buttons}>
                {buttons.map((item) => {
                  return (
                    <Button {...item} key={item.text}>
                      {item.text}
                    </Button>
                  )
                })}
              </div>
            </div>
            <div className={styles.right}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverViewCard
